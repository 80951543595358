
import { defineComponent } from 'vue';
import ApplicationAssembly from '../components/ApplicationAssembly.vue';

export default defineComponent({
  name: 'Index',
  components: {
    ApplicationAssembly,
  },
});
